import Select from '@paljs/ui/Select';
import { Card, CardBody } from '@paljs/ui/Card';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import '../../styles/common.css';
import {
  requireCheck,
  resetRequired,
  validateGst,
  randomString,
  isDummyMail,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  checkNumber,
  authCheck,
  ADMIN,
  getPageAccess,
  getPageAccessFromState,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import toast, { Toaster } from 'react-hot-toast';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  margin-bottom: 10px;
  padding: 4px;
`;

const SupplierPage = () => {
  const [supplierData, setSupplierData] = useState('');
  const [role, setRole] = useState('');
  const [userType, setUserType] = useState('');
  const [userCode, setUserCode] = useState('');
  const [name, setName] = useState('');
  const [shortName, setShortName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPincode] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [contactPerson1, setContactPerson1] = useState('');
  const [contactPhone1, setContactPhone1] = useState('');
  const [contactEmail1, setContactEmail1] = useState('');
  const [contactPerson2, setContactPerson2] = useState('');
  const [contactPhone2, setContactPhone2] = useState('');
  const [contactEmail2, setContactEmail2] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [CINnumber, setCINnumber] = useState('');
  const [GSTnumber, setGSTnumber] = useState('');
  const [pickId, setPickId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showData, setShowData] = useState([]);
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);

  const authState = useContext(GlobalStateData);
  const pageAccess = getPageAccessFromState(authState);
  const [isSave, setSave] = useState(pageAccess.write === 1);

  useEffect(() => {
    authCheck(authState.authData);
    getApi();
    setRole(3);
    setUserType(3);
  }, []);

  const modalWrapperClick = (e) => {
    if (e.target.classList.contains('popup-parent')) {
      handleModalClose();
    }
  };

  const handleModalOpen = (id) => {
    document.getElementsByTagName('body')[0].style.position = 'relative';
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    showSupplier(id);
  };

  const handleModalClose = (event) => {
    setIsModalOpen(false);
    document.getElementsByTagName('body')[0].style.position = 'unset';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  };

  const popup = isModalOpen ? (
    <div className="popup-parent" onClick={(e) => modalWrapperClick(e)}>
      <div className="popup-wrapper">
        <div
          className="pop-up-close"
          onClick={() => {
            handleModalClose();
          }}
        >
          x
        </div>
        <div className="pop-content">
          <h5>Suplier Data</h5>
          <table className="pop-up-table">
            <tr>
              <td>Code</td>
              <td>{showData.userCode}</td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{showData.name}</td>
            </tr>
            <tr>
              <td>Short Name</td>
              <td>{showData.shortName}</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>
                {showData.address1},{showData.address2},{showData.address3},{showData.city}, {showData.district}
                {showData.state}, {showData.pincode}
              </td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>
                #1: {showData.phone1} <br />
                #2: {showData.phone2}
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{isDummyMail(showData.email, showData.phone1) == true ? '' : showData.email}</td>
            </tr>
            <tr>
              <td>Contact Person #1</td>
              <td>
                Name: {showData.contactPerson1} <br />
                Phone: {showData.contactPhone1} <br />
                Email: {showData.contactEmail1} <br />
              </td>
            </tr>
            <tr>
              <td>Contact Person #2</td>
              <td>
                Name: {showData.contactPerson2} <br />
                Phone: {showData.contactPhone2} <br />
                Email: {showData.contactEmail2} <br />
              </td>
            </tr>
            <tr>
              <td>CIN</td>
              <td>{showData.CINnumber}</td>
            </tr>
            <tr>
              <td>GST No.</td>
              <td>{showData.GSTnumber}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  ) : null;

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'userCode':
        setUserCode(event.target.value);
        break;
      case 'name':
        setName(event.target.value);
        break;
      case 'shortName':
        setShortName(event.target.value);
        break;
      case 'address1':
        setAddress1(event.target.value);
        break;
      case 'address2':
        setAddress2(event.target.value);
        break;
      case 'address3':
        setAddress3(event.target.value);
        break;
      case 'city':
        setCity(event.target.value);
        break;
      case 'district':
        setDistrict(event.target.value);
        break;
      case 'state':
        setState(event.target.value);
        break;
      case 'pincode':
        setPincode(checkNumber(event.target, '6.0'));
        break;
      case 'phone1':
        setPhone1(checkNumber(event.target, '10.0'));
        break;
      case 'phone2':
        setPhone2(checkNumber(event.target, '10.0'));
        break;
      case 'contactPerson1':
        setContactPerson1(event.target.value);
        break;
      case 'contactPhone1':
        setContactPhone1(checkNumber(event.target, '10.0'));
        break;
      case 'contactEmail1':
        setContactEmail1(event.target.value);
        break;
      case 'contactPerson2':
        setContactPerson2(event.target.value);
        break;
      case 'contactPhone2':
        setContactPhone2(checkNumber(event.target, '10.0'));
        break;
      case 'contactEmail2':
        setContactEmail2(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        setUsername(event.target.value);
        setPassword(event.target.value);
        break;
      case 'CINnumber':
        setCINnumber(event.target.value);
        break;
      case 'GSTnumber':
        setGSTnumber(event.target.value);
        break;
    }
  };

  const clearFormData = () => {
    setSave(pageAccess.write === 1);
    setPickId('');
    setUserCode('');
    setName('');
    setShortName('');
    setAddress1('');
    setAddress2('');
    setAddress3('');
    setCity('');
    setDistrict('');
    setState('');
    setPincode('');
    setPhone1('');
    setPhone2('');
    setContactPerson1('');
    setContactPhone1('');
    setContactEmail1('');
    setContactPerson2('');
    setContactPhone2('');
    setContactEmail2('');
    setEmail('');
    setUsername('');
    setPassword('');
    setCINnumber('');
    setGSTnumber('');
    resetRequired();
  };

  const getApi = () => {
    showLoader();
    axios
      .get(baseUri() + 'users?filters[userType][$eq]=3&sort[0]=id:desc', {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setSupplierData(response.data);
        if (tableFilter) {
          filterChange(tableFilter, response.data);
        } else {
          setTableData(response.data);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const postApi = () => {
    const postData = {
      role,
      userType,
      userCode,
      name,
      shortName,
      address1,
      address2,
      address3,
      city,
      district,
      state,
      pincode,
      phone1,
      phone2,
      contactPerson1,
      contactPhone1,
      contactEmail1,
      contactPerson2,
      contactPhone2,
      contactEmail2,
      email,
      username,
      password,
      CINnumber,
      GSTnumber,
    };
    let validate = requireCheck(postData, supplierData, 'userCode', pickId);
    let gstVal = validateGst();
    if (!validate || !gstVal) {
      return false;
    }
    let dummyMailPart = phone1 + '_ubl' + randomString() + '@email.com';
    for (const key in postData) {
      if (postData[key] == '') {
        if (key == 'email' || key == 'username' || key == 'password') {
          postData[key] = dummyMailPart;
        } else {
          delete postData[key];
        }
      }
    }
    showLoader();
    if (pickId) {
      axios
        .put(baseUri() + 'users' + '/' + pickId, postData, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          hideLoader();
          toast.error('Record already exist');
        });
    } else {
      axios
        .post(baseUri() + 'users', postData, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          hideLoader();
          toast.error('Record already exist');
        });
    }
  };

  const editSupplier = (id) => {
    clearFormData();
    showLoader();
    axios
      .get(baseUri() + 'users/' + id, {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setSave(pageAccess.edit === 1);
        setPickId(response.data.id);
        setUserCode(response.data.userCode);
        setName(response.data.name);
        setShortName(response.data.shortName);
        setAddress1(response.data.address1);
        setAddress2(response.data.address2);
        setAddress3(response.data.address3);
        setCity(response.data.city);
        setDistrict(response.data.district);
        setState(response.data.state);
        setPincode(response.data.pincode);
        setPhone1(response.data.phone1);
        setPhone2(response.data.phone2);
        setContactPerson1(response.data.contactPerson1);
        setContactPhone1(response.data.contactPhone1);
        setContactEmail1(response.data.contactEmail1);
        setContactPerson2(response.data.contactPerson2);
        setContactPhone2(response.data.contactPhone2);
        setContactEmail2(response.data.contactEmail2);
        if (isDummyMail(response.data.email, response.data.phone1)) {
          setEmail('');
          setUsername('');
          setPassword('');
        } else {
          setEmail(response.data.email);
          setUsername(response.data.email);
          setPassword(response.data.email);
        }
        setCINnumber(response.data.CINnumber);
        setGSTnumber(response.data.GSTnumber);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const showSupplier = (id) => {
    showLoader();
    axios
      .get(baseUri() + 'users/' + id, {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setShowData(response.data);
        setIsModalOpen(true);
        hideLoader();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      })
      .catch((error) => {
        //return  error;
      });
  };

  const deleteSupplier = (id) => {
    const removeGroup = window.confirm('Would you like to delete this supplier?');
    if (removeGroup) {
      showLoader();
      setPickId(id);
      axios
        .delete(baseUri() + 'users/' + id, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };
  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            onClick={() => {
              editSupplier(row.id);
            }}
          >
            {' '}
            <EvaIcon name="edit" />{' '}
          </ActionBtn>
          &nbsp; &nbsp;
          <ActionBtn
            data-tag="allowRowEvents"
            className="btn-rad"
            onClick={() => {
              handleModalOpen(row.id);
            }}
          >
            {' '}
            <EvaIcon name="eye" />{' '}
          </ActionBtn>
          {pageAccess.delete === 1 && (
            <ActionBtn
              data-tag="allowRowEvents"
              className="abtndel-color"
              onClick={() => {
                deleteSupplier(row.id);
              }}
            >
              {' '}
              <EvaIcon name="trash" />{' '}
            </ActionBtn>
          )}
        </div>
      ),
    },
    {
      name: 'Name',
      selector: (row) => `${row.userCode} - ${row.name}`,
      sortable: true,
      maxWidth: '25%',
    },
    {
      name: 'SName',
      selector: (row) => `${row.shortName}`,
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'Address',
      cell: (row) => (
        <div>
          {row.address1}, {row.address2 ? row.address2 + ',' : ''}
          {row.address3 ? row.address3 + ',' : ''} <br />
          {row.city}, {row.district + ','} <br />
          {row.state} - {row.pincode + ','}
        </div>
      ),
      sortable: true,
      maxWidth: '30%',
    },
    {
      name: 'Phone No.',
      cell: (row) => (
        <div>
          #1: {row.phone1} <br />
          #2: {row.phone2}
        </div>
      ),
      sortable: true,
      maxWidth: '15%',
    },
    {
      name: 'Email Id.',
      selector: (row) => `${isDummyMail(row.email, row.phone1) == true ? '' : row.email}`,
      sortable: true,
      maxWidth: '10%',
    },
  ];
  const filterColumns = [
    'userCode',
    'name',
    'shortName',
    'address1',
    'address2',
    'address3',
    'district',
    'city',
    'pincode',
    'state',
    'phone1',
    'phone2',
    'email',
  ];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = supplierData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };
  return (
    <>
      {popup}
      <Toaster />
      <SEO title="Supplier Master" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> Supplier Master</header>
            <CardBody>
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Supplier Code <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="userCode"
                      placeholder="Supplier Code *"
                      onChange={(e) => handleChange(e)}
                      value={userCode}
                      className="userCode require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Supplier Name <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="name"
                      placeholder="Supplier Name *"
                      onChange={(e) => handleChange(e)}
                      value={name}
                      className="name require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Short Name <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="shortName"
                      placeholder="Short Name *"
                      onChange={(e) => handleChange(e)}
                      value={shortName}
                      className="shortName require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Address 1 <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="address1"
                      placeholder="Address 1 *"
                      onChange={(e) => handleChange(e)}
                      value={address1}
                      className="address1 require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Address 2 </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="address2"
                      placeholder="Address 2"
                      onChange={(e) => handleChange(e)}
                      value={address2}
                      className="address2"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Address 3 </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="address3"
                      placeholder="Address 3"
                      onChange={(e) => handleChange(e)}
                      value={address3}
                      className="address3"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    City <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="city"
                      placeholder="City *"
                      onChange={(e) => handleChange(e)}
                      value={city}
                      className="city require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    District <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="district"
                      placeholder="District *"
                      onChange={(e) => handleChange(e)}
                      value={district}
                      className="district require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    State <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="state"
                      placeholder="State *"
                      onChange={(e) => handleChange(e)}
                      value={state}
                      className="state require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    PinCode <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="pincode"
                      maxLength="6"
                      placeholder="PinCode *"
                      onChange={(e) => handleChange(e)}
                      value={pincode}
                      className="pincode require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}></Col>
                <Col breakPoint={{ xs: 12, md: 4 }}></Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Supplier eMail </label>
                  <Input fullWidth size="Small">
                    <input
                      type="email"
                      name="email"
                      placeholder="Supplier eMail"
                      onChange={(e) => handleChange(e)}
                      value={email}
                      className="email"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Phone #1 <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="phone1"
                      maxLength="10"
                      placeholder="Phone #1 *"
                      onChange={(e) => handleChange(e)}
                      value={phone1}
                      className="phone1 require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Phone #2 </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="phone2"
                      maxLength="10"
                      placeholder="Phone #2"
                      onChange={(e) => handleChange(e)}
                      value={phone2}
                      className="phone2"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Supplier CIN </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="CINnumber"
                      maxLength="21"
                      placeholder="Supplier CIN"
                      onChange={(e) => handleChange(e)}
                      value={CINnumber}
                      className="CINnumber"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Supplier GST No. </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="GSTnumber"
                      maxLength="15"
                      placeholder="Supplier GST No."
                      onChange={(e) => handleChange(e)}
                      value={GSTnumber}
                      className="GSTnumber validateGst"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}></Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Supplier Contact Person #1 <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="contactPerson1"
                      placeholder="Supplier Contact Person #1 *"
                      onChange={(e) => handleChange(e)}
                      value={contactPerson1}
                      className="contactPerson1 require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Supplier Contact Person #1 Phone No.<span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="contactPhone1"
                      maxLength="10"
                      placeholder="Supplier Contact Person #1 Phone No. *"
                      onChange={(e) => handleChange(e)}
                      value={contactPhone1}
                      className="contactPhone1 require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Supplier Contact Person #1 eMail</label>
                  <Input fullWidth size="Small">
                    <input
                      type="email"
                      name="contactEmail1"
                      placeholder="Supplier Contact Person #1 eMail"
                      onChange={(e) => handleChange(e)}
                      value={contactEmail1}
                      className="contactEmail1"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Supplier Contact Person #2 </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="contactPerson2"
                      placeholder="Supplier Contact Person #2"
                      onChange={(e) => handleChange(e)}
                      value={contactPerson2}
                      className="contactPerson2"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Supplier Contact Person #2 Phone No.</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="contactPhone2"
                      maxLength="10"
                      placeholder="Supplier Contact Person #2 Phone No."
                      onChange={(e) => handleChange(e)}
                      value={contactPhone2}
                      className="contactPhone2"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Supplier Contact Person #2 eMail</label>
                  <Input fullWidth size="Small">
                    <input
                      type="email"
                      name="contactEmail2"
                      placeholder="Supplier Contact Person #2 eMail"
                      onChange={(e) => handleChange(e)}
                      value={contactEmail2}
                      className="contactEmail2"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Button
                    status="Success"
                    type="button"
                    disabled={!isSave}
                    className="btnrad"
                    shape=""
                    halfWidth
                    onClick={postApi}
                  >
                    Save
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Supplier Master</header>
            <CardBody>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default SupplierPage;
